import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import global_es from './translations/es/global.json'
import global_gl from './translations/gl/global.json'

i18next.init({
  interpolation: { escapeValue: false },
  lng: "es",
  resources:{
    es:{
      global: global_es,
    },
    gl:{
      global: global_gl,
    },
  }
})

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>

  </React.StrictMode>,
  document.getElementById('root')
);