import React from 'react'
import './work.css'

const Work = (mensaje) => {
  return (
    <div className='wk'>
        <div className='wk-browser'>
        <div className='wk-circle'></div>
        <div className='wk-circle'></div>
        <div className='wk-circle'></div>
        </div>
        
        <a href={mensaje.enlace} target='_blank'><img src={mensaje.img} alt='Mazaroca web' className='wk-img'  data-toggle="tooltip" data-placement="top" title={mensaje.emergente} /></a><div>{mensaje.texto}</div>
       
   </div>
  )
}

export default Work