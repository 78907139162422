import React from 'react'
import "./intro.css"
import me from '../../img/me.png'
import { useTranslation } from 'react-i18next'


function Intro() {

  const [t, i18n] = useTranslation("global");


  return (
    <div className='i'>
      <a className='i-button' href="#" onClick={() => i18n.changeLanguage("gl")}>GAL</a>
      <a className='i-button' href="#" onClick={() => i18n.changeLanguage("es")}>ESP</a>
  
      <div className='i-left'>
        <div className='i-left-wrapper'>
          <h2 className='i-intro'>{t("intro.text-intro")}</h2>
          <h1 className='i-name'>Miguel López Patricio</h1>
          <div className='i-title'>
            <div className='i-title-wrapper'>
              <div className='i-title-item'>{t("intro.item-1")}</div>
              <div className='i-title-item'>{t("intro.item-2")}</div>
              <div className='i-title-item'>{t("intro.item-3")}</div>
              <div className='i-title-item'>{t("intro.item-4")}</div>
              <div className='i-title-item'>{t("intro.item-5")}</div>

            </div>
          </div>

          <p className='i-desc'>{t("intro.desc")}</p>
        </div>

      </div>
      <div className='i-right'>
   
        <div className='i-bg'>

        </div>
        <img src={me} alt='' className='i-img'></img>
      </div>


    </div>
  )
}

export default Intro