import React, { useRef } from 'react'
import './contact.css'
import phone from '../../img/phone.png'
import email from '../../img/email.png'
import address from '../../img/address.png'
import emailjs from 'emailjs-com'
import linkedin from '../../img/linkedin.png'
import github from '../../img/github.png'
import { useTranslation } from 'react-i18next'


const Contact = () => {

  const formRef = useRef()

  const [t, i18n] = useTranslation("global");


  const handleSubmit=(e)=>{
    e.preventDefault()
    emailjs.sendForm('service_57opm9j', 'template_axe47e1', formRef.current, 'noqjStM-_P8DZFv0W')
    .then((result) => {
        console.log(result.text);
    }, (error) => {
        console.log(error.text);
    });

  }


  return (
    <div className='c'>
        <div className='c-bg'></div>
        <div className='c-wrapper'>
        <div className='c-left'>
          <h1 className='c-title'>{t("contact.title")}</h1>
          <div className='c-info'>
            <div className='c-info-item'>
              <img src={phone} alt="teléfono" className='c-icon' />
              <a href="tel:653611988">653 611 988</a>
            </div>
            <div className='c-info-item'>
              <img src={email} alt="e-mail logo" className='c-icon' />
              <a href="mailto:ml.patri@gmail.com">ml.patri@gmail.com</a>
            </div>
            <div className='c-info-item'>
              <img src={address} alt="dirección logo" className='c-icon' />
              {t("contact.online")}
            </div>
            <div className='c-info-item'>
            <a href='https://www.linkedin.com/in/miguel-lopez-patricio/' target='_blank'>
              <img src={linkedin} alt="linkedid logo" className='c-icon' />
              </a>
              <a href='https://github.com/miguellopezpatricio' data-toggle="tooltip" data-placement="top"  title='Ir a mi GitHub' target='_blank'>
              <img src={github} alt="github logo" className='c-icon' />
              </a>

         
            </div>
          
          </div>
        </div>
        <div className='c-right'>
          <p className='c-desc'>
            <b>{t("contact.desc")}</b>
            <br/>
            {t("contact.desc-2")}
          </p>

          <form ref={formRef} onSubmit={handleSubmit}>
          <input type="text" placeholder={t("contact.placeholder-name")} name="nombre_usuario" />
          <input type="text" placeholder="Asunto" name="asunto_usuario" />
          <input type="text" placeholder="Email" name="email_usuario" />
          <textarea rows="5" placeholder={t("contact.placeholder-message")} name='mensaje_usuario'/>
      <div className='form-consent'>{t("contact.consent")}</div>
     <br/>     <button>Enviar</button>
          </form>
        </div>
        </div>
    </div>
  )
}

export default Contact