import React from 'react'
import './works.css'
import armadoresweb from '../../img/works/screenshots/armadores01.png'
import comuniapp from '../../img/works/comuniapp.gif'
import spaceinvaders from '../../img/works/spaceinvaders.gif'
import calculinha from '../../img/works/calculinha.gif'
import mazarocaweb from '../../img/works/screenshots/mazaroca01.png'
import webs from '../../img/works/webs.gif'
import marineiros from '../../img/works/screenshots/marineiros.png'

import Work from '../work/Work'
import {works} from '../../data'
import { useTranslation } from 'react-i18next'

const Works = () => {
 
  const [t, i18n] = useTranslation("global");

  return (
    <div className='w'>
      <div className='w-texts'>


      
          <h1 className='w-title'>{t("works.work-title")}</h1>
          <p className='w-desc'>{t("works.work-desc")}</p>
      </div>

        <div className='w-list'>

        <Work img={mazarocaweb} enlace="https://www.mazaroca.com" emergente='Visita la web' texto="Restaurante A Casa de Rosa de Mazaroca"/>
        <Work img={comuniapp} />
        <Work img={armadoresweb} enlace="https://www.armadorespuntadelmoral.com"  emergente='Visita la web' />
        <Work img={spaceinvaders} />
        <Work img={marineiros}  enlace="https://www.mariñeirosporundia.com" emergente='Visita la web' />
        <Work img={calculinha} />

        
      </div>

    </div>
  )
}

export default Works