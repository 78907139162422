import About from './components/about/About';
import Contact from './components/contact/Contact';
import Intro from './components/intro/Intro'
import Works from './components/works/Works';
import CookieConsent from 'react-cookie-consent';
import Footer from './components/footer/Footer';
import { useTranslation } from 'react-i18next'
import Header from './components/header/Header';

const App = () => {

  const [t, i18n] = useTranslation("global");

  return <div>
  
   
    <Intro/>
    <About/>
    <Works/>
    <Contact/>
  
  <CookieConsent debug={true}
  style={{textAlign:"center"}}
  buttonStyle={{background:"#59b256"}}
  buttonText={t("app.cookie-button")}>{t("app.cookie")}</CookieConsent>
  </div>;
};

export default App;