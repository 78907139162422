import React from 'react'
import './about.css'
import coding from '../../img/coding.jpg'
import { useTranslation } from 'react-i18next'


const About = () => {

  const [t, i18n] = useTranslation("global");

  return (
    <div className='a'>
        <div className='a-left'>
            <div className='a-card bg'></div>
            <div className='a-card'>
                <img className='a-img' src={coding} alt='camara' />
            </div>
        </div>
        <div className='a-right'>
            <h1 className='a-title'>{t("about.title")}</h1>
            <p className='a-sub'>
            </p>
            <p className='a-desc'>{t("about.desc")}</p>
        </div>
    </div>
  )
}

export default About